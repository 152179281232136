<template>
  <div id="bmarketing-edit">
    <div class="crumbs">
      <router-link to="/control/companyb">首页</router-link>
      <label>></label>
      <router-link to="/control/bmarketing">推广宣传</router-link>
      <label>></label>
      <a>编辑产品推广</a>
    </div>
    <div class="add-cont">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="200px"
        hide-required-asterisk
      >
        <el-form-item label="产品名称" prop="productPopId">
          <span>{{ name }}</span>
          <!-- <el-select v-model="ruleForm.productId" placeholder="请选择产品">
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="推广周期" prop="period">
          <el-date-picker
            v-model="ruleForm.period"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="预期开始时间" prop="time">
          <el-input v-model="ruleForm.time" disabled></el-input>
        </el-form-item>
        <el-form-item class="form-handle">
          <button type="button" @click="submitForm('ruleForm')">提交</button>
          <router-link to="/control/bmarketing">返回</router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "bmarketing-edit",
  data() {
    return {
      name:"",
      ruleForm: {
        productPopId: "",
        period: [],
        time: "",
      },
      productList: [],
      rules: {
        productId: [
          {
            required: true,
            message: "请选择产品",
            trigger: "blur",
          },
        ],
        period: [
          {
            type: "array",
            required: true,
            message: "请选择推广周期",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    time(n) {
      if (n) {
        const [time] = n;
        this.ruleForm.time = this.$dayjs(time).format("YYYY-MM-DD");
      } else {
        this.ruleForm.time = "";
      }
    },
  },
  computed: {
    time() {
      return this.ruleForm.period;
    },
  },
  mounted() {
    this.getProductPop();
  },
  methods: {
    getProductPop() {
      let id = this.$route.query.id;
      this.$axios.get(`/product/getProductPopById/${id}`).then((res) => {
        if (res.state === 200) {
          console.log(res.data);
          this.ruleForm.productPopId = res.data.id;
          this.productId = res.data.productId;
          this.ruleForm.period = [res.data.beginDate, res.data.endDate];
          this.ruleForm.time = res.data.time;
          this.name = res.data.name;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false;
        const [beginDate, endDate] = this.ruleForm.period;
        this.$axios
          .post(
            "/product/updateProductPro",
            {
              id:this.ruleForm.productPopId,
              productId: this.productId,
              beginDate: this.$dayjs(beginDate).format("YYYY-MM-DD"),
              endDate: this.$dayjs(endDate).format("YYYY-MM-DD"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.state === 200) {
              this.$router.push("/control/bmarketing");
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#bmarketing-edit {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .add-cont {
    min-height: 394px;
    height: auto;
    border: 1px solid #ededed;
    padding: 64px 0 0;
    ::v-deep.el-form {
      display: flex;
      flex-direction: column;
      .el-form-item {
        margin-bottom: 32px;
        .el-form-item__label {
          padding: 0 0 0 64px;
          text-align: left;
          font-size: 20px;
          line-height: 34px;
          color: #333;
        }
        .el-form-item__content {
          line-height: 34px;
          .el-input {
            width: 224px;
            input {
              height: 34px;
              line-height: 34px;
            }
            .el-input__icon {
              line-height: 34px;
            }
          }
          .el-date-editor {
            height: 36px;
            .el-input__icon {
              line-height: 30px;
            }
          }
        }
        &.form-handle {
          height: 36px;
          margin: 32px 0 64px;
          .el-form-item__content {
            display: flex;
            justify-content: center;
            line-height: 36px;
            font-size: 18px;
            margin: 0 !important;
            & > a {
              width: 150px;
              height: 36px;
              line-height: 34px;
              text-align: center;
              text-decoration: none;
              border-width: 1px;
              border-style: solid;
              cursor: pointer;
              margin: 0 12px;
              border-color: #dadada;
              color: #333;
            }
            & > button {
              width: 150px;
              height: 36px;
              background: #3883ff;
              font-size: 18px;
              color: #fff;
              outline: none;
              border: none;
              cursor: pointer;
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
}
</style>
